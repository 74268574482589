// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-applications-animal-nutrition-js": () => import("./../../../src/pages/applications/animal-nutrition.js" /* webpackChunkName: "component---src-pages-applications-animal-nutrition-js" */),
  "component---src-pages-applications-food-js": () => import("./../../../src/pages/applications/food.js" /* webpackChunkName: "component---src-pages-applications-food-js" */),
  "component---src-pages-applications-human-nutrition-js": () => import("./../../../src/pages/applications/human-nutrition.js" /* webpackChunkName: "component---src-pages-applications-human-nutrition-js" */),
  "component---src-pages-applications-index-js": () => import("./../../../src/pages/applications/index.js" /* webpackChunkName: "component---src-pages-applications-index-js" */),
  "component---src-pages-applications-industrial-use-js": () => import("./../../../src/pages/applications/industrial-use.js" /* webpackChunkName: "component---src-pages-applications-industrial-use-js" */),
  "component---src-pages-applications-personal-care-js": () => import("./../../../src/pages/applications/personal-care.js" /* webpackChunkName: "component---src-pages-applications-personal-care-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-email-disclaimer-js": () => import("./../../../src/pages/email-disclaimer.js" /* webpackChunkName: "component---src-pages-email-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-vitaserve-js": () => import("./../../../src/pages/vitaserve.js" /* webpackChunkName: "component---src-pages-vitaserve-js" */)
}

